/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@500&display=swap');
.he {
    font-family: 'Heebo', sans-serif !important;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    color: inherit;
}

button {
    cursor: pointer;
}


/* main global css */


/* html elements */

html,
body {
    direction: rtl;
    line-height: 1.3;
}

h1 {
    font-size: 5vw;
}

h2 {
    font-size: 3.6vw;
    margin-bottom: 2vw;
    line-height: 0.9;
}

h3 {
    font-size: 2.6vw;
}

h4 {}

h5 {}

h6 {}

p {
    font-size: 1.15vw;
    padding-bottom: 1vw;
    font-weight: 300;
}

p strong {
    font-family: 'BahijNazanin-Bold';
}

.ac-text-align-center {
    text-align: center;
}

.ac-text-align-right {
    text-align: right;
}


/* flex */

.ac-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ac-flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.ac-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.ac-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.ac-flexalign-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ac-flexalign-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.ac-flexalign-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.ac-alignitems-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.ac-justify-spacebetween {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ac-justify-space-even {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-evenly;
}

.ac-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ac-justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.ac-justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}


/* end flex */

.ac-container-component {
    width: 75vw;
    margin: 0 auto;
}

.ac-container-component.ac-full-width-container-component {
    width: 100%;
}


/*app*/

.ac-full-page-height {
    height: 100vh;
}

.ac-inner-component {
    padding: 1vw;
}

ul li {
    /*list-style-type: square;*/
    font-size: 1.15vw;
    line-height: 1.4;
    margin-right: 1.2vw;
    font-weight: 300;
    text-indent: 2vw;
}

ul li strong {
    font-family: 'BahijNazanin-Bold';
}


/*ul li::marker {
	font-size: 2vw;
}*/

ul {
    list-style: none;
    counter-reset: my-awesome-counter;
}

li {
    counter-increment: my-awesome-counter;
}

li::before {
    content: counter(my-awesome-counter);
    /* Style away! */
}

li::before {
    background: #333045;
    width: 0.8vw;
    height: 0.8vw;
    display: inline-block;
    line-height: 0.6;
    color: transparent;
    margin-right: -4vw;
    margin-left: 1vw;
}

ul li::before {
    background: #333045;
}

ul li::before {
    background: #333045;
}

ol,
ul {
    list-style: none;
    margin-bottom: 3vw;
    margin-right: 1vw;
}

.ac-button-component {
    font-size: 1.4vw;
    color: #fff;
    background: transparent;
    border: 0.15vw solid #fff;
    margin-left: 1vw;
    padding: 0.3vw 2.5vw;
    font-weight: 700;
}

.ac-app-yellow {
    /*color: #FFE820;
	color:#FFE852;*/
    color: #ffe820;
}

.ac-pink-color {
    color: #e20359;
}

.ac-secondery-color {
    color: #333045;
}

.ac-white-color {
    color: #fff;
}

ol,
ul {
    list-style: none;
    margin-bottom: 3vw;
}

img {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.ac-footer-bg-color {
    background-color: #3b3b3b;
}

button:focus {
    border: 0;
    /*outline:0*/
}

.ac-show-on-mobile {
    display: none;
}

.ac-hide-on-mobile {
    display: show;
}

.ac-bigger {
    font-size: 2.2vw;
}

@media all and (max-width: 1000px) {
    br {
        display: none;
    }
    .ac-show-on-mobile {
        display: block;
    }
    .ac-container-component {
        width: 90%;
    }
    .ac-hide-on-mobile {
        display: none;
    }
    .ac-show-on-mobile.ac-show-flex-mobile {
        display: flex;
    }
    .ac-hide-on-mobile {
        display: none;
    }
    h1 {
        font-size: 11vw;
        margin-bottom: 5.5vw;
    }
    h2 {
        font-size: 8.5vw;
        margin-bottom: 7vw;
    }
    h3 {
        font-size: 6.5vw;
    }
    p,
    ul li {
        font-size: 4.5vw;
        margin-bottom: 2vw;
        line-height: 1.3;
        font-weight: 400;
    }
    ul li br {
        display: none;
    }
    html,
    body {
        overflow-x: hidden;
    }
    li::before {
        background: #333045;
        width: 1.5vw;
        height: 1.5vw;
        display: inline-block;
        line-height: 0.7;
        color: transparent;
        margin-right: -5.4vw;
        margin-left: 2vw;
    }
    ul li strong {
        font-weight: 900;
        font-family: 'Heebo', sans-serif;
    }
    p strong {
        font-weight: 700;
    }
    .ac-bigger {
        font-size: 5.5vw;
    }
}

.ac-app-green {
    color: #2aff00;
}

.ac-app-segol {
    color: #333046;
}


/*.ac-app-yellow1 {
    color: #ffef39;
}*/

.ac-italic {
    font-style: italic;
}

.ac-secion-component-part-14 button {
    cursor: unset;
}

.ac-animate {
    display: block !important;
    animation: animate 5s forwards;
    background: #fff !important;
    color: #000 !important;
}

.ac-user-scroll {
    background: #fff !important;
}

.ac-user-scroll a {
    color: #000 !important;
}

.ie .ac-secion-component-part-8 h2 {
    font-size: 8.3vw;
}

.ie .ac-secion-component-part-8 h2 .ac-app-yellow,
.ie .ac-secion-component-part-8 h2 .ac-white-color {
    font-size: 6.5vw;
}

.ie .ac-secion-component-part-9 p {
    font-size: 2.8vw;
}

.ac-secion-component-part-1 .ac-oveflow.ac-show-on-mobile {
    display: none;
}

.ac-secion-component-part-11 h2 {
    font-size: 6.5vw;
}

.ie .ac-secion-component-part-11 p {
    font-size: 3vw;
}

@media (max-width: 1000px) {
    .ie .ac-secion-component-part-8 h1 {
        letter-spacing: 0.1vw;
        font-size: 8vw;
    }
    .ie #part-14 .ac-text-bg {
        display: block;
        width: max-content;
        margin: 0 auto;
        margin-top: 0.3vw;
    }
    .ie-6 .ac-secion-component-part-14 button {
        width: 52vw;
    }
    .ie-6 #part-14 .ac-text-bg {
        width: 26vw;
    }
    .ie-6 .ac-secion-component-part-14 .ac-icons-inner .ac-icon-text-component {
        margin: 0 auto;
    }
}

#part-15 {
    display: none;
}

.hoc .ac-fullpage-menu {
    top: 0;
}

@media (min-width: 1000px) {
    .ac-secion-component-part-11 h2,
    .ac-secion-component-part-3 h2 {
        font-size: 4.5vw;
    }
    .ac-secion-component-part-18 .ac-city-box.ac-galil:hover .ac-show-on-hover a {
        border: 0.1vw solid #fff;
        margin-left: 0.4vw;
        padding: 0.1vw 0.75vw;
        font-size: 0.9vw;
        text-decoration: none;
        border-radius: 1vw;
        float: right;
        margin-bottom: 0.2vw;
        font-weight: 800;
    }
    .ac-fullpage-menu.ac-user-scroll ul li a:hover,
    .hoc .ac-user-scroll .ac-menu-items-wrapper ul li:hover a,
    .hoc .ac-menu-items-wrapper ul li:hover a {
        color: #fc2c7b !important;
        /*background:black;*/
    }
    .hoc .ac-user-scroll .ac-menu-items-wrapper ul li a.ac-active,
    .hoc .ac-menu-items-wrapper ul li a.ac-active {
        color: #fc2c7b !important;
    }
}

@media (max-width: 1000px) {
    .ac-YouTube-iframe-wrapper {
        margin-top: -19vw;
    }
    .ac-secion-component-part-1 {
        padding-top: 1vw;
        padding-bottom: 14vw;
        background-position-x: -21vw;
        height: 94vh;
    }
    #part-15 {
        height: 15vw;
        display: none;
    }
    .ac-secion-component-part-top {
        top: 0;
    }
    .hoc .ac-fullpage-menu {
        z-index: 9999999;
    }
    .ac-secion-component-part-11 h2 {
        font-size: 9vw;
    }
    .ac-secion-component-part-18 .ac-city-box:hover .ac-button-component:hover,
    .ac-secion-component-part-18 .ac-city-box:hover .ac-show-on-hover a:hover,
    .ac-secion-component-part-18 .ac-city-box:hover .ac-button-component,
    .ac-secion-component-part-18 .ac-city-box .ac-show-on-hover a,
    .ac-secion-component-part-18 .ac-city-box:hover .ac-show-on-hover a {
        border: 1px solid #fff;
        margin-left: 0.5vw;
        color: #fff;
        padding: 2vw 4vw;
        font-size: 4.5vw;
        text-decoration: none;
        border-radius: 6vw;
        float: none;
        margin-bottom: 3vw;
        font-weight: 800;
        display: inline-block;
    }
    .ac-secion-component-part-18 .ac-city-box:hover .ac-button-component:hover,
    .ac-secion-component-part-18 .ac-city-box:hover .ac-show-on-hover a:hover,
    .ac-secion-component-part-18 .ac-city-box .ac-show-on-hover a:hover,
    .ac-secion-component-part-18 .ac-city-box .ac-button-component:hover {
        background: rgba(0, 0, 0, 0.5);
    }
}

.ac-secion-component-part-18 .ac-city-box h2 {
    font-weight: 100;
}

@media (max-width: 1000px) {
    .ac-secion-component-part-top {
        top: 0;
    }
    .ac-secion-component-part-8 .ac-button-component.ac-to-order,
    .ac-secion-component-part-8 .ac-button-component.ac-to-order:hover {
        background-position: center;
        background-size: 100% auto;
    }
}

.ac-screen-reader-only {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
    display: block;
    background: #fff;
    color: #000;
}

a:focus,
button:focus,
input:focus,
.slick-slider .slick-track:focus,
#ac-accessibility-title-wrapper:focus,
#ac-accessibility-menu li .btn:focus,
.slick-prev:focus,
.slick-next:focus {
    border: 3px solid black;
    outline: 0;
}

button:focus,
#siur-offers label:focus input,
#madrega-1:focus,
#madrega-2:focus,
#madrega-3:focus,
.area button:focus,
#siur-offers a.ac-gototeacher-siur:focus,
input:focus .checkmark,
#aria-label:focus {
    border: 3px solid black;
    outline: 0;
}

*[aria-hidden='true']:focus a,
*[aria-hidden='true']:focus div,
*[aria-hidden='true']:focus button {
    outline: 0;
}

*[aria-hidden='false']:focus a,
*[aria-hidden='false']:focus div,
*[aria-hidden='false']:focus button {
    border: 3px solid black;
    outline: 0;
}

.slick-slider [aria-hidden="true"][tabindex="-1"]:focus a,
.slick-slider [aria-hidden="true"][tabindex="-1"]:focus div,
.slick-slider [aria-hidden="true"][tabindex="-1"]:focus button,
.slick-slider [aria-hidden="true"][tabindex="-1"] a,
.slick-slider [aria-hidden="true"][tabindex="-1"] div,
.slick-slider [aria-hidden="true"][tabindex="-1"] button {
    border: 0;
    outline: 0
}