/** 
  * Fonts css main file
*/
/*@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@500;900&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300;600&display=swap');*/


@font-face {
 	font-family: 'anomalia-ultrabold-aaa';
    src: url('./anomalia-ultrabold-aaa.eot');
 	src: url('./anomalia-ultrabold-aaa.woff') format('woff');
 
}


/*@font-face {
     font-family: 'anomalia-regular-aaa';
     src:url('./anomalia-regular-aaa.eot');
     src:url('./anomalia-regular-aaa.woff') format('woff');

}*/

@font-face {
    font-family: 'BahijNassim';
    src: url('./BahijNassim.eot'); /* IE9 Compat Modes */
    src: url('./BahijNassim.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./BahijNassim.woff2') format('woff2'), /* Super Modern Browsers */
         url('./BahijNassim.woff') format('woff'), /* Pretty Modern Browsers */
         url('./BahijNassim.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./BahijNassim.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'BahijNazanin-Bold';
    src: url('./BahijNazanin-Bold.eot'); /* IE9 Compat Modes */
    src: url('./BahijNazanin-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./BahijNazanin-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('./BahijNazanin-Bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('./BahijNazanin-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./BahijNazanin-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

html,body , p {
/*font-family: 'Changa', sans-serif;*/
font-family: 'BahijNassim';
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
	/*font-family: 'Changa', sans-serif;*/
	font-family: 'BahijNazanin-Bold';
	font-weight: bold;
}